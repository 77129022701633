"use client";
import dynamic from "next/dynamic";
const Main = dynamic(() => import("@/components/layout/main/Main"));
const HeroSection = dynamic(
  () => import("@/components/feature-page/hero-section/HeroSection")
);
const Footer = dynamic(() => import("@/components/layout/footer/Footer"));

export default function Home() {
  return (
    <div>
      <HeroSection />
      <Main />
      <Footer />
    </div>
  );
}
